import React from 'react'
import { useState, useEffect } from "react";
import Homepage from '../pages/Homepage'
import { BrowserRouter as Router, Routes, Route, Link, useLocation} from "react-router-dom";
import {PrivateRoutes, PrivatePerm} from '../components/PrivateRoutes';
import { AnimatePresence } from 'framer-motion';
import VideoPage from '../pages/VideoPage';
import moment from 'moment/moment';
import 'moment/locale/it'



function AnimateRoutes ()  {
    
    

    const location = useLocation();
    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));

    const timeElapsed = Date.now();  //prende la data attuale in millisecondi
    const today = new Date(timeElapsed);    //converte nel tipo data
    var formattedDate = moment(today).format('DD-MM-YYYY');  //coverte nel formato richiesto
    localStorage.setItem("today", formattedDate);
    const [todayC, setTodayC] = useState(localStorage.getItem("today"));  //variabile che andiamo ad utilizzare



return (

    <AnimatePresence>
    <Routes location={location} key={location.pathname}>




    <Route path="/" element={<Homepage />} /> 
    <Route path="/video" element={<VideoPage />} />



    </Routes>


    </AnimatePresence>

    )

}

export default AnimateRoutes 