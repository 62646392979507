import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import VideoPlayerTor from '../components/VideoPlayerTorrent';


const torrentId = 'magnet:?xt=urn:btih:C3620C23E18884361FD6BB95AF16AB5B886123B1&dn=01.m4v&tr=udp%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce&tr=wss%3a%2f%2fwstracker.online';
const bho03 = 'magnet:?xt=urn:btih:CD0A6AFC3A61ED66F30B5B0E06A45DFB9B11C112&dn=03.m4v&tr=wss%3a%2f%2fwstracker.online'
const bho02 = 'magnet:?xt=urn:btih:270250654AD7080C9C67A5FB66A5726E08FF90E6&dn=02.m4v&tr=wss%3a%2f%2fwstracker.online'


const VideoPage = () => {
  const { state } = useLocation();
  const { src, title, duration, actor, iframeSrc, category, etichette, description, srcVideo } = state;

  return (
 <>
    <nav className='navMobile d-flex align-items-center'>
        <div className='d-flex justify-content-center align-items-center' style={{ padding: 0 }}>
            {/* */} <img src="/images/1.png" alt="Descrizione dell'immagine" />
            </div>
            <div className='d-flex ms-4'>
                <a href='' className='mb-0'>Home</a>
                <a href='' className='ms-3 mb-0'>Category</a>
            </div>
            <div className='col'>
        </div>
    </nav>



    <motion.div
        initial= {{opacity: 0}}
        animate= {{opacity: 1}}
        transition={{ duration: 0.7 }}>
      <div className='' style={{textAlign: "center", marginTop: "100px", padding: "0px 0%"}}>

      <div className='divVideoIframe'>

        {srcVideo !== "false" &&
            <video className='videoIframe' controls poster={src}>
                <source src={srcVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        }

        {iframeSrc !== "false" &&
            <iframe className='videoIframe'  src={iframeSrc} frameborder="0"></iframe>
            

        }
        

        
        <div className='text-start'>
            <h2>{title}</h2>
            <p>{description}</p>
            <p>Duration: {duration}</p>
            <p>Pornostar: {actor.join(', ')}</p>
            <p>Category: <span>{category.join(', ')}</span></p>
            <p>Etichette: <span>{etichette.join(', ')}</span></p>
        </div>
      </div>

     {/*** <VideoPlayerTor torrentId={bho03} /> */} 
     
        <div style={{ marginBottom: "100px" }}></div>
      
        </div>

      </motion.div>
    </>
  );
};

export default VideoPage;
