import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';

const VideoPlayer = ({ videos, difficult, leng }) => {
  const videoRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePreviusVideo = () => {
    if(currentVideoIndex == 0) {
      return
    }
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1) % videos.length);
  };

  const handleGoToLastVideo = () => {
    if( difficult == "Noob"){
      setCurrentVideoIndex(8)
    }
    if( difficult == "Pro"){
      setCurrentVideoIndex(12)
    }
    if( difficult == "Legend"){
      setCurrentVideoIndex(18)
    }
  };

  useEffect(() => {
    // Controlla se il videoRef è definito prima di chiamare play()
    if (videoRef.current) {
      // Imposta il currentTime a 0 per riavviare il video
      videoRef.current.currentTime = 0;
      // Ricarica il video
      videoRef.current.load();
      // Riproduci il video
      videoRef.current.play().catch((error) => {
        // Puoi gestire eventuali errori qui
        console.error('Errore durante la riproduzione automatica:', error);
      });
    }
  }, [currentVideoIndex]);

  return (
    <div style={{ flex: "1" }}>
      <div style={{ width:"100%" }}>
        <div className='row'>
          <div className='col'><h1 style={{color: "#ff9000", textAlign:"left", marginLeft:"6px" }}>{difficult}</h1></div>
          <div className='col'><h1 style={{textAlign:"center", marginBottom:"0px" }}>{currentVideoIndex}/{leng}</h1></div>
          <div className='col'></div>
        </div>
        
      <video
        ref={videoRef}
        controls
        style={{ maxWidth: '100%', height:"250px" }}
        onEnded={handleNextVideo}
      >
        <source src={videos[currentVideoIndex].path} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
      <div>
      <Button onClick={handlePreviusVideo} variant="contained" color='error'>Previous Video</Button>
        <Button onClick={handleNextVideo} variant="contained" color='success'>Next Video</Button>
        <Button onClick={handleGoToLastVideo} variant="contained" color='secondary'>Go to Last Video</Button>
      </div>

    </div>
  );
};

export default VideoPlayer;


