import React from 'react';
import Gallery from './Gallery'; // Assicurati di importare il componente Gallery

//bingato tnaflix babestude  src

const GalleryContainer = () => {
  const gallery1Items = [

    { src: 'https://apovstory.com/content//contentthumbs/10/89/1089-3x.jpg', srcVideo: '/videoInt/mona1.mp4',
      title: 'A Pov Story - Mona Wales - Test Drive', duration: '35 min', actor: ['Mona Wales'], 
      category: ["Fetish", "POV", "MILF", "Etero", "Panterona", "Blonde", "1080p"], description: "Watch, stream and download A Pov Story - Mona Wales - Test Drive at PornOne for free. This - porn video is related to POV, Doggystyle and Old and Young. It was uploaded by ASSPAIN and its 35.32 minutes long. Enjoy a high quality wales sex movie.",
       etichette:["grande", "naturali"], iframeSrc: "false" },

    { src: 'https://t88.pixhost.to/thumbs/265/367038618_xxxfile-org-mona_wales_-_burning_down_the_house.jpg', srcVideo: '/videoInt/mona2.mp4',
        title: 'Mona Wales - Burning Down The House A Pov', duration: '39 min', actor: ['Mona Wales'], 
      category: ["Fetish", "POV", "MILF", "Etero", "Panterona", "Blonde", "1080p"], description: "Watch, stream and download Mona Wales - Burning Down the House A POV Story at PornOne for free. This - porn video is related to Old and Young, Taboo and Mature. It was uploaded by ASSPAIN and its 39.43 minutes long. Enjoy a high quality burning sex movie.",
       etichette:["", ""], iframeSrc: "false" },

    { src: 'https://t45.pixhost.to/thumbs/403/162316690_xxxfile-org-mommy_is_all_i_want_for_christmas_and_brave_sister_-_dava_foxx__cory.jpg', 
      srcVideo: '/videoInt/Davafoxx.m4v', title: 'Dava Foxx in Step Mommy è tutto ciò che voglio per Natale',
       duration: '4 min', actor: ['Dava Foxx'], 
      category: ["milf", "creanoie", , "POV", , "dava", "natale"], description: "", etichette:["", ""], iframeSrc: "false" },
    
    { src: 'https://pornhoarder.pictures/img/large/79/79858640f87d173689_1.jpg', 
      srcVideo: '/videoInt/ada1.mp4',
      title: 'DAVA FOXX WEEKEND AT THE BEACH',
       duration: '40 min', actor: ['Dava Foxx'], 
      category: ["Huge Tits", "Brunette", "POV", "beach"], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://static-ca-cdn.eporner.com/thumbs/static4/8/82/825/8253724/13_360.jpg', 
      srcVideo: '/videoInt/vivianFox2.mp4',
      title: 'Vivian Fox makes Stepson Com Everywhere', duration: '31 min', actor: ['Vivian Fox'], 
      category: ["POV", "Brunette", "Small Tits", , "MILF", , "Blowjob"], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://m.pornpics.vip/xxx/sislovesme/vivian-fox/kzrn-reverse-kateporn/vivian-fox-2.jpg', 
      srcVideo: '/videoInt/viviaFox1.mp4',
      title: 'Jealous Sexy Stepsis Tries To Seduce Her', duration: '51 min', actor: ['Vivian Fox'], 
      category: ["POV", "Brunette", "Small Tits", , "MILF", , "Blowjob"], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://img.24xxx.love/vds/73/9/72867/1-540x360.jpg', 
      srcVideo: '/videoInt/shaySights.mp4',
       title: 'Shay Sights- Big Tits Teacher Wild Public Sex', duration: '27 min', actor: ['Shay Sights'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" }, 

    { src: 'https://pornomadurasgratis.com/wp-content/uploads/2020/11/como-a-una-zorra.jpg', srcVideo: 'https://www.babestube.com/get_file/1/155a4e434d702fccbedcb1325e0dea4bbd44e15324/179000/179189/179189.mp4/?br=684&rnd=1721868317483',
      title: 'Alexa Vega - I Expose My Mom For Being a Slut - Filthy POV', duration: '20 min', actor: ['Alexa Vega'], 
      category: ["POV", "MILF", "Mom", "big ass"], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://www.porn00.org/contents/videos_screenshots/12000/12959/preview.jpg', srcVideo: '/videoInt/tiaCyra.mp4',
      title: ' I Can Not Believe My Stepmom Is Blowing Me', duration: '15 min', actor: ['Tia Cyrus'], 
      category: ["POV", "MILF", "Mom",], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://pics-storage-1.pornhat.com/contents/albums/main/1920x1080/92000/92427/5829222.jpg', 
      srcVideo: '/videoInt/mandyRhea.mp4', title: 'Dad Almost Catches Me & My Stepmom in Our Messed Up Morning Routine! - Mandy Rhea', duration: '17 min', actor: ['Mandy Rhea', 'Adam'], 
      category: ["POV", "MILF", "Blonde", "Bit Tits", "Mom"], description: "", etichette:["", ""], iframeSrc: "false" },

    { src: 'https://imggen.eporner.com/9709040/1920/1080/10.jpg',
      srcVideo: '/videoInt/Mandyflores.m4v',title: 'Horny teen fucked his stepmom', duration: '17 min', actor: ['Mandy Flores'], 
      category: ["POV", "MILF", "Brunette"], description: "", etichette:["", ""], iframeSrc: "false" },

      { src: 'https://cdn.taboojizz.com/wp-content/uploads/2022/12/Family-Therapy-Mothers-Favor-HEATHER-VAHN_thumb1-640x360.jpg',
        srcVideo: '/videoInt/HeatherVahn.m4v',title: 'Step mothers Favor With Heather Vahn', duration: '30 min', actor: ['Alex Adam', "Heather Vahn"], 
        category: ["POV", "MILF", "Brunette"], description: "", etichette:["", ""], iframeSrc: "false" },

    // Aggiungi altri elementi qui
  ];

  const gallery2Items = [
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo A', duration: '5 min', actor: ['Mario'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    // Aggiungi altri elementi qui
  ];

  const gallery3Items = [
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo A', duration: '5 min', actor: ['Mario'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova3.jpg', srcVideo: 'false',title: 'Titolo C', duration: '6 min', actor: ['Peach'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    { src: '/images/prova2.jpg', srcVideo: 'false',title: 'Titolo B', duration: '2 min', actor: ['Luigi'], 
      category: ["", ""], description: "", etichette:["", ""], iframeSrc: "false" },
    // Aggiungi altri elementi qui
  ];

  // Funzione per mescolare un array
  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Funzione per limitare il numero di elementi
  const limitItems = (items, limit) => {
    return items.slice(0, limit);
  };

  // Gallerie
  const galleries = [
    { title: 'POV', items: limitItems(shuffleArray(gallery1Items), 12) },
    { title: 'Lista 2', items: limitItems(shuffleArray(gallery2Items), 12) },
    { title: 'Lista 3', items: limitItems(shuffleArray(gallery3Items), 12) },
    // Aggiungi altre gallerie qui se necessario
  ];

  // Mescola le gallerie
  const shuffledGalleries = shuffleArray(galleries);

  return (
    <div className='gallery-container-wrapper ms-3'>
      {shuffledGalleries.map((gallery, index) => (
        <div key={index} className='mb-4'>
          <h2>{gallery.title}</h2>
          <Gallery items={gallery.items} />
        </div>
      ))}
    </div>
  );
};

export default GalleryContainer;
