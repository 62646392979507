import React, { useRef, useState, useEffect } from 'react';
import GalleryItem from './GalleryItem';
import './Gallery.css';

const Gallery = ({ items }) => {
  const galleryRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = () => {
    const gallery = galleryRef.current;
    const maxScrollLeft = gallery.scrollWidth - gallery.clientWidth;
    setIsAtStart(gallery.scrollLeft === 0);
    setIsAtEnd(gallery.scrollLeft >= maxScrollLeft);
  };

  const scrollLeft = () => {
    galleryRef.current.scrollBy({ left: -450, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const gallery = galleryRef.current;
    const maxScrollLeft = gallery.scrollWidth - gallery.clientWidth;
    if (gallery.scrollLeft + 500 >= maxScrollLeft) {
      gallery.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      galleryRef.current.scrollBy({ left: 450, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const gallery = galleryRef.current;
    gallery.addEventListener('scroll', checkScrollPosition);
    return () => {
      gallery.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  useEffect(() => {
    checkScrollPosition(); // Check the position initially
  }, []);

  return (
    <div className='gallery-container'>
      <button
        className={`scroll-button left ${isAtStart ? 'hidden' : ''}`}
        onClick={scrollLeft}
      >
        ◀
      </button>
      <div className='gallery' ref={galleryRef}>
        {items.map((item, index) => (
          <div className='gallery-item-wrapper' key={index}>
            <GalleryItem
              src={item.src}
              title={item.title}
              duration={item.duration}
              actor={item.actor}
              iframeSrc={item.iframeSrc}
              category= {item.category}
              etichette= {item.etichette}
              description= {item.description}
              srcVideo= {item.srcVideo}
            />
          </div>
        ))}
      </div>
      <button
        className={`scroll-button right ${isAtEnd ? 'hidden' : ''}`}
        onClick={scrollRight}
      >
        ▶
      </button>
    </div>
  );
};

export default Gallery;
