import React from 'react'
import VideoPlayer from '../components/VideoPlayer';
import JuicyAdsComponent from '../components/JuicyAdsComponent';
import JuicyAdsBanner from '../components/JuicyAdsBanner';
import JuicyAdsBannerTop from '../components/JuicyAdsBannerTop';
import JuicyAdsBannerLeader from '../components/LeaderAds';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import seedrandom from 'seedrandom';
import GalleryContainer from '../components/GalleryContainer';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const style = {
  width: '300px',
  marginTop: "30px",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
};

function Homepage ()  {

    const [flagCont, setFlagCont] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [currentDifficult, setCurrentDifficult] = React.useState("Pro");
    const [lengIndexArray, setLengIndexArray] = React.useState(0);

    const [filteredVideos, setFilteredVideos] = useState([]);
    localStorage.setItem("naviBottom", 0);

// Funzione per mescolare un array utilizzando l'algoritmo di Fisher-Yates moderno
function shuffleArray(array, seed) {
  const rng = seedrandom(seed);
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(rng() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
}

// Utilizza la data corrente come seme
const currentTimestamp = new Date().getTime();
const seed = currentTimestamp.toString();


function durstenfeldShuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

//_______________________________________________________________________________________________________________________-
    //blowJob, liking, cowgirl, cowgirlRev, doggy, missionary, spooing, mast
    const videos = [
      { id: '01', categoria: 'auto', posizione: 'cowgirl', velocita: 'L', path: '/videos/01.m4v' },
      { id: '02', categoria: 'auto', posizione: 'cowgirl', velocita: 'V', path: '/videos/02.m4v' },
      { id: '03', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'M', path: '/videos/03.m4v' },
      { id: '04', categoria: 'auto', posizione: 'liking', velocita: 'L', path: '/videos/04.m4v' },
      { id: '113', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/113.m4v' },
      { id: '06', categoria: 'bicicletta', posizione: 'spooing', velocita: 'M', path: '/videos/06.m4v' },
      { id: '07', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/07.m4v' },
      { id: '08', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/08.m4v' },
      { id: '09', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/09.m4v' },
      { id: '10', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/10.m4v' },
      { id: '11', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/11.m4v' },
      { id: '05', categoria: 'auto', posizione: 'doggy', velocita: 'V', path: '/videos/05.m4v' },
      { id: '12', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/12.m4v' },
      { id: '13', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/13.m4v' },
      { id: '16', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'M', path: '/videos/16.m4v' },
      { id: '17', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/17.m4v' },
      { id: '19', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/19.m4v' },
      { id: '20', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/20.m4v' },
      { id: '22', categoria: 'bicicletta', posizione: 'doggy', velocita: 'M', path: '/videos/22.m4v' },
      { id: '23', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/23.m4v' },
      { id: '26', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/26.mp4' },
      { id: '27', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/27.m4v' },
      { id: '28', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/28.mp4' },
      { id: '29', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/29.m4v' },
      { id: '30', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/30.m4v' },
      { id: '103', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/103.m4v' },
      { id: '33', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/33.m4v' },
      { id: '34', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/34.mp4' },
      { id: '35', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/35.m4v' },
      { id: '36', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/36.mp4' },
      { id: '37', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'V', path: '/videos/37.m4v' },
      { id: '38', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/38.m4v' },
      { id: '39', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/39.m4v' },
      { id: '40', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'V', path: '/videos/40.m4v' },
      { id: '41', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/41.m4v' },
      { id: '42', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/42.m4v' },
      { id: '43', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/43.m4v' },
      { id: '44', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/44.mp4' },
      { id: '45', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/45.m4v' },
      { id: '46', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/46.mp4' },
      { id: '47', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/47.mp4' },
      { id: '48', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/48.m4v' },
      { id: '49', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/49.m4v' },
      { id: '50', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/50.m4v' },
      { id: '51', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/51.m4v' },
      { id: '52', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/52.m4v' },
      { id: '53', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/53.mp4' },
      { id: '54', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/54.m4v' },
      { id: '55', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/55.m4v' },
      { id: '56', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/56.m4v' },
      { id: '57', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/57.m4v' },
      { id: '58', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/58.mp4' },
      { id: '59', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/59.mp4' },
      { id: '60', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'V', path: '/videos/60.m4v' },
      { id: '61', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/61.mp4' },
      { id: '62', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/62.m4v' },
      { id: '64', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/64.m4v' },
      { id: '65', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/65.mp4' },
      { id: '66', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/66.m4v' },
      { id: '67', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/67.m4v' },
      { id: '68', categoria: 'bicicletta', posizione: 'liking', velocita: 'V', path: '/videos/68.m4v' },
      { id: '69', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/69.m4v' },
      { id: '70', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/70.m4v' },
      { id: '21', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/21.m4v' },
      { id: '71', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/71.m4v' },
      { id: '72', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/72.m4v' },
      { id: '73', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'V', path: '/videos/73.m4v' },
      { id: '74', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/74.m4v' },
      { id: '75', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/75.mp4' },
      { id: '77', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/77.m4v' },
      { id: '78', categoria: 'bicicletta', posizione: 'liking', velocita: 'V', path: '/videos/78.mp4' },
      { id: '79', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/79.m4v' },
      { id: '80', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/80.m4v' },
      { id: '81', categoria: 'bicicletta', posizione: 'missionary', velocita: 'V', path: '/videos/81.m4v' },
      { id: '82', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/82.mp4' },
      { id: '83', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/83.mp4' },
      { id: '84', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/84.m4v' },
      { id: '85', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/85.m4v' },
      { id: '86', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'V', path: '/videos/86.m4v' },
      { id: '119', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/119.m4v' },
      { id: '88', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/88.m4v' },
      { id: '89', categoria: 'bicicletta', posizione: 'liking', velocita: 'V', path: '/videos/89.m4v' },
      { id: '90', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/90.m4v' },
      { id: '91', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/91.m4v' },
      { id: '128', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/128.m4v' },
      { id: '92', categoria: 'bicicletta', posizione: 'spooing', velocita: 'V', path: '/videos/92.m4v' },
      { id: '93', categoria: 'bicicletta', posizione: 'liking', velocita: 'V', path: '/videos/93.m4v' },
      { id: '94', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/94.m4v' },
      { id: '95', categoria: 'bicicletta', posizione: 'doggy', velocita: 'V', path: '/videos/95.m4v' },
      { id: '96', categoria: 'bicicletta', posizione: 'liking', velocita: 'V', path: '/videos/96.m4v' },
      { id: '97', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/97.m4v' },
      { id: '98', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/98.m4v' },
      { id: '99', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/99.m4v' },
      { id: '100', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/100.m4v' },
      { id: '101', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/101.m4v' },
      { id: '102', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/102.m4v' },
      { id: '112', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/112.m4v' },
      { id: '116', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/116.m4v' },
      { id: '124', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/124.m4v' },
      { id: '106', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/106.m4v' },
      { id: '107', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/107.m4v' },
      { id: '108', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/108.m4v' },
      { id: '109', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/109.m4v' },
      { id: '110', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/110.m4v' },
      { id: '111', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/111.m4v' },
      { id: '120', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/120.m4v' },
      { id: '121', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/121.m4v' },
      { id: '115', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/115.m4v' },
      { id: '127', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/127.m4v' },
      { id: '130', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/130.m4v' },
      { id: '131', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/131.m4v' },
      { id: '132', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/132.m4v' },
      { id: '133', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/133.m4v' },
      { id: '134', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/134.mp4' },
      { id: '135', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/135.m4v' },
      { id: '136', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/136.m4v' },
      { id: '137', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/137.mp4' },
      { id: '117', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/117.m4v' },
      { id: '118', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/118.m4v' },
      { id: '138', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/138.mp4' },
      { id: '114', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/114.m4v' },
      { id: '141', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/141.mp4' },
      { id: '142', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/142.mp4' },
      { id: '143', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/143.mp4' },
      { id: '144', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/144.mp4' },
      { id: '145', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/145.mp4' },
      { id: '146', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/146.mp4' },
      { id: '147', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/147.mp4' },
      { id: '148', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/148.mp4' },
      { id: '129', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/129.m4v' },
      { id: '149', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/149.mp4' },
      { id: '150', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/150.mp4' },
      { id: '151', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/151.mp4' },
      { id: '152', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/152.mp4' },
      { id: '153', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/153.mp4' },
      { id: '154', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/154.mp4' },
      { id: '155', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/155.mp4' },
      { id: '156', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/156.mp4' },
      { id: '157', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/157.mp4' },
      { id: '158', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/158.mp4' },
      { id: '122', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/122.m4v' },
      { id: '159', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/159.mp4' },
      { id: '160', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/160.mp4' },
      { id: '161', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/161.mp4' },
      { id: '162', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/162.mp4' },
      { id: '163', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/163.mp4' },
      { id: '164', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/164.mp4' },
      { id: '165', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/165.mp4' },
      { id: '166', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/166.mp4' },
      { id: '167', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/167.mp4' },
      { id: '168', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/168.mp4' },
      { id: '169', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/169.mp4' },
      { id: '170', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/170.mp4' },
      { id: '126', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/126.m4v' },
      { id: '171', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/171.mp4' },
      { id: '172', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/172.mp4' },
      { id: '173', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/173.mp4' },
      { id: '174', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/174.mp4' },
      { id: '175', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/175.mp4' },
      { id: '176', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/176.mp4' },
      { id: '177', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/177.mp4' },
      { id: '178', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/178.mp4' },
      { id: '179', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/179.mp4' },
      { id: '104', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/104.m4v' },
      { id: '180', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/180.mp4' },
      { id: '181', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/181.mp4' },
      { id: '182', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/182.mp4' },
      { id: '183', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/183.mp4' },
      { id: '184', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/184.mp4' },
      { id: '185', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/185.mp4' },
      { id: '186', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/186.mp4' },
      { id: '187', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/187.mp4' },
      { id: '188', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/188.mp4' },
      { id: '31', categoria: 'bicicletta', posizione: 'final', velocita: 'V', path: '/videos/31.m4v' },
      { id: '189', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/189.mp4' },
      { id: '190', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/190.mp4' },
      { id: '191', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/191.mp4' },
      { id: '192', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/192.mp4' },
      { id: '193', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/193.mp4' },
      { id: '123', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/123.m4v' },
      { id: '194', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/194.mp4' },
      { id: '195', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/195.mp4' },
      { id: '196', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/196.mp4' },
      { id: '197', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/197.mp4' },
      { id: '198', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/198.mp4' },
      { id: '199', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/199.mp4' },
      { id: '200', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/200.mp4' },
      { id: '201', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/201.mp4' },  
      { id: '202', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/202.mp4' }, 
      { id: '203', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/203.mp4' }, 
      { id: '204', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/204.mp4' }, 
      { id: '205', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/205.mp4' }, 
      { id: '206', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/206.mp4' }, 
      { id: '207', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/207.mp4' }, 
      { id: '208', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/208.mp4' },
      { id: '209', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/209.mp4' },
      { id: '210', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/210.mp4' },
      { id: '212', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/212.mp4' },
      { id: '213', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/213.mp4' },
      { id: '214', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/214.mp4' },
      { id: '215', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/215.mp4' },
      { id: '216', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/216.mp4' }, 
      { id: '217', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/217.mp4' }, 
      { id: '218', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/218.mp4' }, 
      { id: '219', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/219.mp4' }, 
      { id: '220', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/220.mp4' }, 
      { id: '221', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/221.mp4' }, 
      { id: '222', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/222.mp4' }, 
      { id: '223', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/223.mp4' }, 
      { id: '224', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/224.mp4' }, 
      { id: '225', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/225.mp4' }, 
      { id: '226', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/226.mp4' }, 
      { id: '227', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/227.mp4' }, 
      { id: '228', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/228.mp4' }, 
      { id: '229', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/229.mp4' }, 
      { id: '230', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/230.mp4' }, 
      { id: '231', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/231.mp4' },
      { id: '232', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/232.mp4' }, 
      { id: '233', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/233.mp4' }, 
      { id: '234', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/234.mp4' }, 
      { id: '125', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/125.m4v' },
      { id: '235', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/235.mp4' }, 
      { id: '236', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/236.mp4' }, 
      { id: '237', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/237.mp4' }, 
      { id: '238', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/238.mp4' },
      { id: '105', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/105.m4v' }, 
      { id: '239', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/239.mp4' }, 
      { id: '240', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/240.mp4' }, 
      { id: '241', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/241.mp4' }, 
      { id: '242', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/242.mp4' }, 
      { id: '244', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/244.mp4' }, 
      { id: '245', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/245.mp4' }, 
      { id: '246', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/246.mp4' }, 
      { id: '247', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/247.mp4' }, 
      { id: '248', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/248.mp4' }, 
      { id: '249', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/249.mp4' }, 
      { id: '250', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/250.mp4' }, 
      { id: '251', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/251.mp4' }, 
      { id: '252', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/252.mp4' }, 
      { id: '253', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/253.mp4' }, 
      { id: '254', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/254.mp4' }, 
      { id: '255', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/255.mp4' }, 
      { id: '256', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/256.mp4' }, 
      { id: '257', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/257.mp4' }, 
      { id: '258', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/258.mp4' }, 
      { id: '259', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/259.mp4' }, 
      { id: '260', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/260.mp4' }, 
      { id: '262', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/262.mp4' }, 
      { id: '263', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/263.mp4' }, 
      { id: '264', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/264.mp4' }, 
      { id: '265', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/265.mp4' }, 
      { id: '266', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/266.mp4' }, 
      { id: '267', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/267.mp4' }, 
      { id: '268', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/268.mp4' }, 
      { id: '269', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/269.mp4' }, 
      { id: '270', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/270.mp4' }, 
      { id: '271', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/271.mp4' }, 
      { id: '272', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/272.mp4' }, 
      { id: '273', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/273.mp4' }, 
      { id: '274', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/274.mp4' }, 
      { id: '275', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/275.mp4' }, 
      { id: '276', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/276.mp4' }, 
      { id: '277', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/277.mp4' }, 
      { id: '278', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/278.mp4' }, 
      { id: '279', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/279.mp4' }, 
      { id: '280', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/280.mp4' }, 
      { id: '281', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/281.mp4' }, 
      { id: '282', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/282.mp4' }, 
      { id: '283', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/283.mp4' }, 
      { id: '284', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/284.mp4' }, 
      { id: '285', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/285.mp4' }, 
      { id: '286', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/286.mp4' }, 
      { id: '287', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/287.mp4' }, 
      { id: '288', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/288.mp4' }, 
      { id: '289', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/289.mp4' }, 
      { id: '290', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/290.mp4' }, 
      { id: '291', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/291.mp4' }, 
      { id: '292', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/292.mp4' }, 
      { id: '294', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/294.mp4' }, 
      { id: '295', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/295.mp4' }, 
      { id: '296', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/296.mp4' }, 
      { id: '297', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/297.mp4' }, 
      { id: '298', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/298.mp4' }, 
      { id: '299', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/299.mp4' }, 
      { id: '300', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/300.mp4' }, 
      { id: '301', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/301.mp4' }, 
      { id: '302', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/302.mp4' }, 
      { id: '303', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/303.mp4' }, 
      { id: '304', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/304.mp4' }, 
      { id: '305', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/305.mp4' }, 
      { id: '306', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/306.mp4' }, 
      { id: '307', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/307.mp4' }, 
      { id: '308', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/308.mp4' }, 
      { id: '309', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/309.mp4' }, 
      { id: '310', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/310.mp4' }, 
      { id: '311', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/311.mp4' }, 
      { id: '312', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/312.mp4' },  
      { id: '314', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/314.mp4' }, 
      { id: '315', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/315.mp4' }, 
      { id: '316', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/316.mp4' }, 
      { id: '317', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/317.mp4' }, 
      { id: '318', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/318.mp4' }, 
      { id: '319', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/319.mp4' }, 
      { id: '320', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/320.mp4' }, 
      { id: '321', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/321.mp4' },  
      { id: '323', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/323.mp4' }, 
      { id: '324', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/324.mp4' }, 
      { id: '325', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/325.mp4' }, 
      { id: '326', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/326.mp4' }, 
      { id: '327', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/327.mp4' }, 
      { id: '328', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/328.mp4' }, 
      { id: '329', categoria: 'bicicletta', posizione: 'final', velocita: 'F', path: '/videos/329.mp4' },
      { id: '330', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/330.mp4' }, 
      { id: '331', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/331.mp4' }, 
      { id: '332', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/332.mp4' }, 
      { id: '334', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/334.mp4' }, 
      { id: '335', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'F', path: '/videos/335.mp4' }, 
      { id: '336', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/336.mp4' }, 
      { id: '337', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/337.mp4' }, 
      { id: '338', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/338.mp4' }, 
      { id: '339', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/339.mp4' }, 
      { id: '341', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/341.mp4' }, 
      { id: '342', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/342.mp4' }, 
      { id: '343', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/343.mp4' }, 
      { id: '344', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/344.mp4' }, 
      { id: '345', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/345.mp4' }, 
      { id: '346', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/346.mp4' }, 
      { id: '347', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/347.mp4' }, 
      { id: '348', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/348.mp4' }, 
      { id: '349', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/349.mp4' }, 
      { id: '350', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/350.mp4' }, 
      { id: '351', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/351.mp4' }, 
      { id: '352', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/352.mp4' }, 
      { id: '353', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/353.mp4' }, 
      { id: '354', categoria: 'bicicletta', posizione: 'liking', velocita: 'F', path: '/videos/354.mp4' }, 
      { id: '355', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/355.mp4' }, 
      { id: '356', categoria: 'bicicletta', posizione: 'doggy', velocita: 'F', path: '/videos/356.mp4' }, 
      { id: '357', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/357.mp4' }, 
      { id: '358', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/358.mp4' }, 
      { id: '359', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/359.mp4' }, 
      { id: '360', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/360.mp4' }, 
      { id: '361', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/361.mp4' }, 
      { id: '362', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/362.mp4' }, 
      { id: '363', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/363.mp4' }, 
      { id: '364', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/364.mp4' }, 
      { id: '365', categoria: 'bicicletta', posizione: 'blowjob', velocita: 'L', path: '/videos/365.mp4' }, 
      { id: '366', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/366.mp4' }, 
      { id: '367', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/367.mp4' }, 
      { id: '368', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/368.mp4' }, 
      { id: '369', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/369.mp4' }, 
      { id: '370', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/370.mp4' }, 
      { id: '373', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/373.mp4' }, 
      { id: '374', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/374.mp4' }, 
      { id: '375', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/375.mp4' }, 
      { id: '376', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/376.mp4' }, 
      { id: '377', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/377.mp4' }, 
      { id: '378', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/378.mp4' }, 
      { id: '379', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/379.mp4' }, 
      { id: '380', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/380.mp4' }, 
      { id: '381', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/381.mp4' },
      { id: '382', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/382.mp4' }, 
      { id: '383', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/383.mp4' }, 
      { id: '384', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/384.mp4' }, 
      { id: '385', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/385.mp4' }, 
      { id: '386', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/386.mp4' }, 
      { id: '387', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/387.mp4' }, 
      { id: '388', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/388.mp4' }, 
      { id: '389', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/389.mp4' }, 
      { id: '390', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/390.mp4' }, 
      { id: '391', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/391.mp4' }, 
      { id: '392', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/392.mp4' }, 
      { id: '393', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/393.mp4' }, 
      { id: '394', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/394.mp4' }, 
      { id: '395', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/395.mp4' }, 
      { id: '396', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/396.mp4' }, 
      { id: '397', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/397.mp4' }, 
      { id: '398', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/398.mp4' }, 
      { id: '399', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/399.mp4' }, 
      { id: '400', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/400.mp4' }, 
      { id: '401', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/401.mp4' }, 
      { id: '402', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/402.mp4' }, 
      { id: '403', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/403.mp4' }, 
      { id: '404', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/404.mp4' }, 
      { id: '405', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/405.mp4' }, 
      { id: '406', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/406.mp4' }, 
      { id: '407', categoria: 'bicicletta', posizione: 'final', velocita: 'L', path: '/videos/407.mp4' }, 
      { id: '408', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/408.mp4' }, 
      { id: '409', categoria: 'bicicletta', posizione: 'doggy', velocita: 'L', path: '/videos/409.mp4' }, 
      { id: '410', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/410.mp4' }, 
      { id: '411', categoria: 'bicicletta', posizione: 'liking', velocita: 'L', path: '/videos/411.mp4' }, 
      { id: '412', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/412.mp4' }, 
      { id: '413', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/413.mp4' }, 
      { id: '414', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/414.mp4' }, 
      { id: '415', categoria: 'bicicletta', posizione: 'cowgirl', velocita: 'L', path: '/videos/415.mp4' }, 
      { id: '416', categoria: 'bicicletta', posizione: 'spooing', velocita: 'L', path: '/videos/416.mp4' }, 
      { id: '417', categoria: 'bicicletta', posizione: 'missionary', velocita: 'L', path: '/videos/417.mp4' }, 
      { id: '418', categoria: 'bicicletta', posizione: 'cowgirlRev', velocita: 'L', path: '/videos/418.mp4' }, 


      // Aggiungi altri attributi secondo necessità
    ];
    
    let navigate = useNavigate();
  

    const handleChange = (event) => {
      setCurrentDifficult(event.target.value);
    };

    //______________________________________________________________________________________--
    const handleGenerate = () => {
      var ciao = false
      durstenfeldShuffle(videos)
      shuffleArray(videos, seed);
      durstenfeldShuffle(videos)

      if(open) {
        window.location.reload(true); // true indica un ricaricamento forzato
      }

      var indexArray
      var numMin
      if(currentDifficult == "Noob") {
        indexArray = 9
        numMin = 1
        setLengIndexArray(8)
      }
      if(currentDifficult == "Pro") {
        indexArray = 13
        numMin = 2
        setLengIndexArray(12)
      }
      if(currentDifficult == "Legend") {
        indexArray = 19
        numMin = 3
        setLengIndexArray(18)
      }

      // Funzione di filtraggio per ottenere almeno 3 video per ogni posizione
      const filterVideosByPosition = (videos) => {
        const videosByPosition = {};
        const selectedVideos = [];
        let finalVideo = null;
    
        // Mescola casualmente l'array di video
    
        // Itera sui video mescolati
        videos.forEach((video) => {
          // Seleziona almeno 3 video per ogni posizione
          if (video.posizione === 'final') {
            // Se c'è un video chiamato "position: final", memorizzalo solo se non è già stato selezionato
            if (!finalVideo && !videosByPosition['final']) {
              finalVideo = video;
              videosByPosition['final'] = true;
            }
          } else if (!videosByPosition[video.posizione] || videosByPosition[video.posizione] < numMin) {
            videosByPosition[video.posizione] = (videosByPosition[video.posizione] || 0) + 1;
            selectedVideos.push(video);
          }
        });
    
        // Aggiungi il video "position: final" alla fine se presente
        if (finalVideo) {
          selectedVideos.push(finalVideo);
        }
    
        return selectedVideos;
      };
    
      // Esegui il filtraggio e imposta l'array filtrato nello stato
      const newFilteredVideos = filterVideosByPosition(videos);
    
      // Ordina l'array mettendo il "blowjob" come primo elemento
      const reorderedVideos = [
        ...newFilteredVideos.filter((video) => video.posizione === 'blowjob'),
        ...newFilteredVideos.filter((video) => video.posizione !== 'blowjob'),
      ];
    
      // Ordina nuovamente l'array per evitare posizioni consecutive uguali
      const finalOrderedVideos = [reorderedVideos[0]];
    
      for (let i = 1; i < reorderedVideos.length; i++) {
        if (reorderedVideos[i].posizione !== finalOrderedVideos[finalOrderedVideos.length - 1].posizione) {
          finalOrderedVideos.push(reorderedVideos[i]);
        }
      }
    
      // Seleziona ulteriori video casuali fino a raggiungere il totale desiderato (18)
      const remainingVideos = videos.filter((video) => !finalOrderedVideos.includes(video));
      const additionalRandomVideos = remainingVideos.sort(() => Math.random() - 0.5).slice(0, indexArray - finalOrderedVideos.length);
    
      // Combinare i video finali
      const trimmedVideos = finalOrderedVideos.concat(additionalRandomVideos);
    
      // Ordina l'array mettendo "position: final" come ultimo elemento
      const finalOrderedArray = [
        ...trimmedVideos.filter((video) => video.posizione !== 'final'),
        ...trimmedVideos.filter((video) => video.posizione === 'final'),
      ];
    
      setFilteredVideos(finalOrderedArray);
      setOpen(true);
      console.log('Filtered Videos:', finalOrderedArray);
    };
    
    return (
        <>
{/**************NAVBAR MOBILE*************************************** */}
<nav className='navMobile d-flex align-items-center'>
  <div className='d-flex justify-content-center align-items-center' style={{ padding: 0 }}>
   {/* */} <img src="/images/1.png" alt="Descrizione dell'immagine" />
  </div>
  <div className='d-flex ms-4'>
    <a href='' className='mb-0'>Home</a>
    <a href='' className='ms-3 mb-0'>Category</a>
  </div>
  <div className='col'>
    
  </div>
</nav>
      <motion.div
        initial= {{opacity: 0}}
        animate= {{opacity: 1}}
        transition={{ duration: 0.7 }}>
      <div className='' style={{textAlign: "center", marginTop: "100px", padding: "0px"}}>
          <h5 >Come si usa: Seleziona prima la difficoltà dopodiché Premi il tasto "generate video" per generare dei video casuali🎬</h5>
          <h5>Il sito è Gratis🆓 e Sicuro🛡️! non c'è bisogno di registrasi❌</h5>
          <h5 className='mb-5'>Buon Divertimento!</h5>
         {/* <JuicyAdsBannerLeader adzone={1042375} width={"100%"} height={102} /> */}
      


  {open &&
      <div style={{ width: "100%", marginTop: "20px" }}>
      <VideoPlayer videos={filteredVideos} difficult={currentDifficult} leng={lengIndexArray} />
    </div>
    }

  {!open && 
  <div style={style}>
  <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Choose the difficulty</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentDifficult}
            label="Choose the difficulty"
            onChange={handleChange}
          >
            <MenuItem value={"Noob"}>Noob</MenuItem>
            <MenuItem value={"Pro"}>Pro</MenuItem>
            <MenuItem value={"Legend"}>Legend</MenuItem>
          </Select>
        </FormControl>
  </div>
  }
      <div >
      <Button variant="contained" color='primary' style={{ width: "200px",height: "100px", marginTop: "30px", fontSize: "18px", fontWeight: "bold" }} onClick={() => { handleGenerate() }}>Generate Video</Button>
      </div>

   {/**  <div id='_2482467' class='_a4adef45f5c1'></div> */}  

      <div className='text-start mt-4'>
        <GalleryContainer />
      </div>

 <div style={{ marginBottom: "100px" }}></div>
      
      </div>

      </motion.div>
        </>
    )

}

export default Homepage 
