import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GalleryItem.css';

const GalleryItem = ({ src, title, duration, actor, iframeSrc, category, etichette, description, srcVideo  }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/video', { state: { src, title, duration, actor, iframeSrc, category, etichette, description, srcVideo } });
  };

  return (
    <div className='gallery-item' onClick={handleClick}>
      <img className="img-fluid" style={{ width: "300px", height: "180px" }} src={src} alt={title} />
      <div className='overlay'>
        <div className='overlay-content'>
          <div className='overlay-title'>{title}</div>
          <div className='overlay-actor mt-3'>{actor.join(', ')}</div>
          <div className='overlay-duration'>Durata: {duration}</div>
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
